export default {
  install(Vue){

    /*
    * api store
    **************************************************************/
    Vue.prototype.$api = {
      state: new Vue({data(){return{
        initialised: false,
        loading: false,
        error: '',
        status: null
      }}}),
      url: '/api',

      // initialise
      init(v){
        if(v.$route.query.preview || !window.$ssr){
          this.initAPI(v);
        } else if(window.$ssr){
          this.initSSR(v);
        } else {
          v.$api.setError(v, 'API init failed', 404);
        }
      },

      // initial data
      initAPI(v){
        // use API for previews and development
        Promise.all([
          v.$apiGet('/menus'),
          v.$apiGet('/options')
        ]).then(async([menus, options]) => {
          v.$app.setCollections(menus, options);
          this.state.initialised = true;
        }).catch(error => {
          v.$routing.routeError(v, error.error, error.status, v.$route.path);
          this.state.initialised = true;
        });
      },
      initSSR(v){
        // prerender when available
        var $ssr = JSON.parse(window.$ssr);
        v.$log($ssr, 'Prerendered data: ');
        v.$app.setCollections($ssr.menus, $ssr.options);
        if($ssr.error){
          v.$api.setError(v, $ssr.error.error, $ssr.error.status);
          v.$routing.routeError(v, $ssr.error.error, $ssr.error.status, v.$route.path);
        } else {
          v.$routing.routePost(v, $ssr.post);
        }
        this.state.initialised = true;
      },

      // errors
      setError(v, error, status){
        this.state.error = error;
        this.state.status = status;
        v.$log(error, status);
      },
      clearError(){
        this.state.error = '';
        this.state.status = null;
      },

      // api loading
      setLoading(){
        this.state.loading = true;
      },
      clearLoading(){
        this.state.loading = false;
      },

      // get headers
      getHeaders(){
        let headers = {
          'Content-Type': 'application/json',
          'Pragma': 'no-cache',
          'Cache-Control': 'no-cache'
        };
        let user = localStorage.getItem('user');
        if(user){
          headers['Authorization'] = 'Bearer ' + JSON.parse(user).jwt;
          headers['hextail-cms'] = 'true';
        }
        return headers;
      }
    };

    /*
    * $_POST API
    **************************************************************/
    Vue.prototype.$apiPost = function(action, data, loadStatus){
      var v = this;
      if(loadStatus){
        v.$api.setLoading();
      }
      v.$log('action: '+action+' data: '+JSON.stringify(data), 'POST');
      return fetch(v.$api.url, {
        method: 'POST',
        headers: this.$api.getHeaders(),
        body: JSON.stringify({
          action: action,
          data: !data ? {} : data
        })
      })
      .then(response => v.$apiProcess(response, loadStatus));
    };

    /*
    * $_GET API
    **************************************************************/
    Vue.prototype.$apiGet = function(params, loadStatus){
      var v = this;
      if(loadStatus){
        v.$api.setLoading();
      }
      var fresh = 'v='+Date.now();
      fresh = params.includes('?') ? '&'+fresh : '?'+fresh;
      v.$log('from: '+v.$api.url+params+fresh, 'GET');
      return fetch(v.$api.url+params+fresh, {
        method: 'GET',
        headers: v.$api.getHeaders()
      })
      .then(response => v.$apiProcess(response, loadStatus));
    };

    /*
    * process response from API
    **************************************************************/
    Vue.prototype.$apiProcess = function(response, loadStatus){
      var v = this;
      if(loadStatus){
        v.$api.clearLoading();
      }
      return response.text().then(text => {
        let api;
        // check for valid JSON
        try {
          let o = JSON.parse(text);
          if (o && typeof o === "object"){
            api = o;
          }
        }
        // catch PHP errors and dumps
        catch (e){
          const status = 500;
          v.$api.setError(v, text+' | '+response.url, status);
          return Promise.reject({text, status});
        }
        // error handling
        if(!response.ok){
          const error = (api.data && api.data.error) || response.statusText;
          const status = response.status;
          v.$api.setError(v, error, status);
          return Promise.reject({error, status});
        }
        // success message
        if(api.data.success){
          v.$log(api.data.success+': '+response.url, 'Success');
        }
        // warning message
        if(api.data.warning){
          v.$log(api.data.warning+': '+response.url, 'Warning');
        }
        // successful data
        const data = api.data;
        v.$log(data, 'data: '+response.url);
        return data;
      });
    };

  }
};
