import { trimEnd } from 'lodash-es';
export default {
  install(Vue){

    /*
    * app store
    **************************************************************/
    Vue.prototype.$app = {
      state: new Vue({data(){return{
        isFrontpage: false,
        thePost: null,
        thePostClasses: []
      }}}),
      menus: {},
      options: {},
      version: process.env.VUE_APP_VERSION,
      sitekey: process.env.VUE_APP_SITE_KEY,
      env: process.env.NODE_ENV,

      // set app collections
      setCollections(menus, options){
        this.menus = menus;
        this.options = options;
      },

      // get page title
      pageTitle(v, post){
        var title = '';
        var siteTitle = this.options.site_title;
        if(post.permalink.frontpage){
          title = post.seo_title ? post.seo_title : siteTitle;
        } else {
          title = post.seo_title ? post.seo_title : post.title;
          title = title+' | '+siteTitle;
        }
        return title;
      },

      // set the current post
      setThePost(v, post){
        // project hook
        v.$project.enterHook(v, post);

        // set the post
        this.state.thePost = post;

        // document title
        var title = this.pageTitle(v, post);
        document.title = title;

        // document desc
        var desc = post.seo_desc || this.options.site_desc || '';
        var description = document.head.querySelector('meta[name="description"]');
        description.setAttribute('content', desc);

        // document canonical
        var url = trimEnd(this.options.site_url || window.location.origin, '/');
        var canonical = document.head.querySelector('link[rel="canonical"]');
        canonical.setAttribute('href', url+post.permalink.path);

        // post theming
        var classes = [
          'post-type-'+post.post_type,
          'template-'+post.template.replace('_', '-')
        ];
        this.state.isFrontpage = false;
        if(post.permalink.frontpage){
          this.state.isFrontpage = true;
          classes.push('frontpage');
        }
        this.state.thePostClasses = classes;

        // Google analtics
        if(v.$ga){
          if(post.error){
            v.$ga.exception(post.title+': '+window.location.href);
          } else {
            v.$ga.page({
              page: post.permalink.path,
              title: title,
              location: window.location.href
            });
          }
        }
      },
    };

    /*
    * home logo action
    **************************************************************/
    Vue.prototype.$frontpageScroll = function(){
      var v = this.$root;
      if(v.$app.state.isFrontpage){
        v.$project.homeLinkHook(v);
      }
    };

  }
};
