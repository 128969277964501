import { get, isString, includes } from 'lodash-es';
export default {
  install(Vue){

    /*
    * media store
    **************************************************************/
    Vue.prototype.$media = {
      state: new Vue({data(){return{
        webp: null
      }}}),

      // initialise
      init(v){
        this.supportsWebp().then(webp => {
          v.$media.state.webp = webp;
        });
      },

      // check for webp support
      // https://stackoverflow.com/a/54120785
      supportsWebp(){
        return new Promise(res => {
          const webp = new Image();
          webp.src = 'data:image/webp;base64,UklGRjoAAABXRUJQVlA4IC4AAACyAgCdASoCAAIALmk0mk0iIiIiIgBoSygABc6WWgAA/veff/0PP8bA//LwYAAA';
          webp.onload = webp.onerror = () => {
            res(webp.height === 2);
          };
        });
      }
    }

    /*
    * get image resized
    **************************************************************/
    Vue.prototype.$imgSrc = function(media, thumbnail, retina) {
      var webp = get(this, '$root.$media.state.webp');
      if(media && media.src && webp !== null && includes(['jpeg', 'jpg', 'png'], media.extention)){
        var ret = retina && get(this, '$root.$window.state.retina') ? '-retina' : '';
        var thumb = thumbnail || 'full';
        var cdn = get(this, '$root.$app.env') === 'production' && window.cdnURL ? window.cdnURL : '';
        var filename = cdn+'/thumbs/'+media.id+'_'+thumb+ret;
        if(webp){
          return filename+'_'+media.extention+'.webp';
        }
        return filename+'.'+media.extention;
      }
      return media.src;
    };

    /*
    * get background thumbnail css
    **************************************************************/
    Vue.prototype.$background = function(media, thumbnail, retina) {
      return {'background-image': 'url('+this.$imgSrc(media, thumbnail, retina)+')'};
    };

    /*
    * fade in image after load
    **************************************************************/
    Vue.directive('image-loaded', {
      bind: (el, binding, vnode) => {
        if(binding.value !== false){
          el.classList.add('image-loading');
          var src = el.getAttribute('src') || el.style.backgroundImage.slice(4, -1).replace(/['"]/g, "");
          var img = new Image();
          img.onload = () => {
            el.classList.remove('image-loading');
            var animation = get(binding, 'value.animation') || 'fade';
            if(isString(binding.value)){
              animation = binding.value;
            }
            el.classList.add(animation);
          };
          img.src = src;
        }
      }
    });

  }
};
