<template>
  <div class="skeleton" />
</template>

<script>
export default {}
</script>

<style lang="scss">
.skeleton {
  background-color: $fade;
  border-radius: 20px;
  width: 100%;
  &:before {
    content: '';
    display: block;
  }
  &.mobile-phone {
    &:before {
      padding-bottom: 200%;
    }
  }
  &.desktop {
    &:before {
      padding-bottom: 70.8%;
    }
  }
  &.feature-image {
    &:before {
      padding-bottom: 47%;
      @media only screen and (max-width: $s){
        padding-bottom: 100%;
        border-radius: 0;
      }
    }
  }
  &.client-logos {
    width: 90%;
    margin: auto;
    padding-bottom: 72px;
    @media only screen and (max-width: $s){
      padding-bottom: 52px;
    }
  }
  &.services-swiper {
    width: 50%;
    margin: auto;
    padding-bottom: 203px;
  }
}
</style>
