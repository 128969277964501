import { find } from 'lodash-es';
export default {
  install(Vue){

    /*
    * routing store
    **************************************************************/
    Vue.prototype.$routing = {
      state: new Vue({data(){return{
        cached: {},
        transitioning: false
      }}}),

      // get API location of post direct from URL path
      postPath(v){
        var path = v.$route.path;
        return path.replace(/\//g, '_') || '_';
      },

      // load post from data
      routePost(v, post){
        // cache route and post data
        this.state.cached[post.permalink.path] = post;
        // set post
        v.$app.setThePost(v, post);
        // add to router
        var route = {
          path: post.permalink.path,
          name: post.title,
          meta: {title: v.$app.pageTitle(v, post)},
          component: () => import('../templates/'+post.template.replace('_', '-'))
        }
        v.$router.addRoutes([route]);
      },

      // error page
      routeError(v, error, status, path){
        var errorPost = {
          title: error+' '+status,
          id: 'error-'+Date.now(),
          post_type: 'page',
          template: 'error',
          error: status,
          permalink: {
            path: path
          }
        };
        // cache route and post data
        this.state.cached[path] = errorPost;
        // set post
        v.$app.setThePost(v, errorPost);
        // add to router
        v.$router.addRoutes([{
          path: path,
          name: errorPost.title+'-'+Date.now(),
          meta: {title: errorPost.title},
          component: () => import('../templates/error')
        }]);
      },

      // vue router change route
      transitioning(){
        this.state.transitioning = true;
      },
      changeRoute(to, from, next, v){
        if(to.path !== from.path){
          if(this.state.transitioning){
            v.$project.interruptedHook(to, from, next, v);
          } else {
            v.$project.leavingHook(to, from, v);
            v.$nextTick(() => {
              next();
            });
          }
        } else {
          next();
        }
      },

      // transition of leaving route completed
      loadRoute(v){
        this.state.transitioning = false;
        var cachedPost = find(this.state.cached, (post) =>
        post.permalink.path === v.$route.path);
        if(cachedPost){
          // loaded cached route
          v.$app.setThePost(v, cachedPost);
        } else {
          // new route
          var postPath = this.postPath(v);
          postPath = v.$route.query.preview ? '/preview/'+postPath+'/' : '/posts/'+postPath;
          v.$apiGet(postPath, true).then(data => {
            this.routePost(v, data);
          }, ({error, status}) => {
            this.routeError(v, error, status, v.$route.path);
          });
        }
      },
    };

  }
};
