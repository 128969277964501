import { Vector3, Ray } from '@babylonjs/core';
import { find, each, endsWith } from 'lodash-es';

/*
* babylon render
**************************************************************/
export function render(v, $b) {
  return function() {
    // treadmill/tile/collisions required
    if(!$b.treadmill || !$b.collision || !$b.state.transitioning){
      return;
    }
    var cameraPos = $b.dolly.position;
    var tiles = $b.treadmill.getChildMeshes(true);

    // record camera velocity
    var previous = $b.previous;
    var position = cameraPos.z;
    $b.velocity = position - previous;
    $b.previous = position;

    // collision detection
    each($b.rays, function(ray){
      var matrix = $b.dolly.getWorldMatrix();
      var pos = Vector3.TransformCoordinates(ray.direction, matrix);
      var dir = Vector3.Normalize(pos.subtract(cameraPos));
      var pewpew = new Ray(cameraPos, dir, ray.length);
      ray.hit = $b.scene.pickWithRay(pewpew).pickedMesh;
    });

    // hit detected
    var collided = find($b.rays, 'hit');
    if(collided && !$b.collided){
      $b.collided = true;
      $b.scene.stopAnimation($b.collision);
      $b.collision.visibility = 1;
      v.$log('Collided '+collided.name+' with '+collided.hit.name, 'Babylon');

    // no hits
    } else if(!collided && $b.collided){
      $b.collided = false;
      $b.scene.beginDirectAnimation($b.collision, $b.animations.collision, 0, $b.settings.transition.collisionSeconds*$b.settings.fps, false, 1);
    }

    // turn on collisions for new meshes
    each(tiles, function(tile){
      var landscape = find(tile.getChildMeshes(true), function(mesh){
        return endsWith(mesh.name, 'Landscape');
      });
      if(landscape && !landscape.isPickable){
        landscape.isPickable = true;
      }
    });

    // garbage collection of tiles behind camera
    if(tiles.length > 1){
      each(tiles, function(tile){
        var landscape = find(tile.getChildMeshes(true), function(mesh){
          return endsWith(mesh.name, 'Landscape');
        });
        if(landscape){
          var position = tile.position;
          var size = landscape.getBoundingInfo().boundingBox.extendSize;
          if(position.z+size.z < cameraPos.z){
            tile.dispose();
          }
        }
      });
    }

  }
}
