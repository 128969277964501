import { SceneLoader, AnimationEvent, BezierCurveEase, Color3, ParticleSystem, GPUParticleSystem, Texture, Vector3, Mesh } from '@babylonjs/core';
import { find, each, endsWith } from 'lodash-es';

// import and setup new tile
function importMesh(v, $b, name){
  return new Promise((resolve) => {
    new SceneLoader.ImportMeshAsync(null, '/babylon/', name+'.glb?v='+v.$app.version, $b.scene)
    .then(results => {
      var root = results.meshes[0];
      root.name = name;
      root.id = name;
      root.rotationQuaternion = null;
      root.setEnabled(false);
      $b.tiles[name].root = root;
      each(['Landscape', 'Beacon', 'Energy'], function(material){
        var mesh = find(results.meshes, function(mesh){
          return endsWith(mesh.name, material);
        });
        if(mesh){
          mesh.material = $b.materials[material.toLowerCase()];
          mesh.isPickable = false;
        }
      });
      v.$log('Imported mesh '+name, 'Babylon');
      resolve();
    });
  });
}

// create new babylon particles
function newParticles(v, $b, mesh){
  var amount = 24;
  if($b.engine.webGLVersion === 1){
    var particles = new ParticleSystem('particles', amount, $b.scene);
  } else {
    var particles = new GPUParticleSystem('particles', {capacity: amount}, $b.scene);
  }
  particles.emitter = mesh;
  particles.isLocal = true;
  particles.particleTexture = new Texture('/babylon/particle.svg?v='+v.$app.version, $b.scene);
  particles.minEmitBox = new Vector3(-1.5, -1, -0.5);
  particles.maxEmitBox = new Vector3(1.5, -1, 1);
  particles.minSize = 0.02;
  particles.maxSize = 0.03;
  particles.minLifeTime = 0.4;
  particles.maxLifeTime = 0.9;
  particles.direction1 = new Vector3(-0.3, 1, 0);
  particles.direction2 = new Vector3(0.3, 1, 0);
  particles.minEmitPower = 5;
  particles.maxEmitPower = 9;
  particles.updateSpeed = 0.0005;
  particles.color1 = $b.colors.pink;
  particles.color2 = $b.colors.teal;
  // particles.colorDead = $b.colors.clear;
  particles.addVelocityGradient(0, 0.5);
  particles.addVelocityGradient(0.8, 0.2);
  particles.addVelocityGradient(1, 0.1);
  particles.preWarmCycles = 50;
  particles.preWarmStepOffset = 40;
  particles.start();
}

// simple fade in
function fadeInMesh(v, $b, mesh, seconds){
  $b.scene.beginDirectAnimation(mesh, [$b.animation({
    name: 'fade in',
    property: 'visibility',
    type: 'FLOAT',
    seconds: seconds,
    values: [0, 1]
  })], 0, seconds*$b.settings.fps, false, 1);
}

// transition to new page
function pageTransition(v, $b, tile, intro){
  $b.number++;
  $b.offset += $b.settings.transition.move;
  $b.state.transitioning = true;
  var number = $b.number;
  var fps = $b.settings.fps;
  var transitionSeconds = $b.settings.transition.seconds;
  var fadeInSeconds = $b.settings.transition.fadeInSeconds;
  var ratio = $b.state.ratio === 'hero-landscape' ? 'landscape' : 'portrait';

  // add tile clone
  var clone = tile.root.clone(tile.root.name+' '+$b.number);
  var meshes = clone.getChildMeshes(true);
  clone.parent = $b.treadmill;
  clone.position.z = $b.offset;

  // optimise
  clone.freezeWorldMatrix();
  each(meshes, function(mesh){
    mesh.freezeWorldMatrix();
  });

  // beacons
  var energy = find(meshes, function(mesh){
    return endsWith(mesh.name, 'Energy');
  });
  if(energy){

    // lights
    var glow = $b.lights.glow.clone(tile.root.name+' glow '+$b.number);
    glow.parent = energy;
    var glowSeconds = $b.settings.glow.seconds;
    each({energy: energy.material, glow: glow}, function(entity, name){
      $b.scene.beginDirectAnimation(entity, $b.animations[name], 0, glowSeconds*fps, true, 1);
    });
    var ring = $b.lights.ring.clone(tile.root.name+' ring '+$b.number);
    ring.parent = energy;
    $b.scene.beginDirectAnimation(ring, $b.animations.ring, 0, glowSeconds*fps, true, 1);

    // arches
    if(tile.root.name === 'who'){
      glow.position = new Vector3(-0.3, 0, 15);
      ring.intensity = 30;
      ring.position = new Vector3(0, 0, 15);
    }

    // particles
    newParticles(v, $b, energy);
  }

  // intro
  var fogAnimation = [];
  var fogPositions = {start: 'fogStart', end: 'fogEnd'};
  if(intro){
    fadeInMesh(v, $b, $b.gradient, v.$project.state.kiosk ? 5 : transitionSeconds * 0.5);

    // fade in fog
    each(fogPositions, function(property, position){
      fogAnimation.push($b.animation({
        name: 'fog '+position,
        property: property,
        type: 'FLOAT',
        seconds:  v.$project.state.kiosk ? 5 : transitionSeconds * 0.66,
        ease: new BezierCurveEase(0, 0, 0, 1),
        values: [$b.scene[property], $b.settings.fog[position]]
      }));
    });

  // transition between tiles
  } else {

    // fade in new tile meshes
    each(meshes, function(mesh){
      fadeInMesh(v, $b, mesh, fadeInSeconds);
    });

    // dip fog to keep black at bottom
    each(fogPositions, function(property, position){
      fogAnimation.push($b.animation({
        name: 'fog '+position,
        property: property,
        type: 'FLOAT',
        seconds: transitionSeconds * 0.5,
        values: [$b.scene[property], $b.settings.fog[position] * 1.5, $b.settings.fog[position]]
      }));
    });

    // dip fog to keep black at bottom
    // var fogAnimation = [$b.animation({
    //   name: 'fog dip',
    //   property: 'fogStart',
    //   type: 'FLOAT',
    //   seconds: transitionSeconds,
    //   values: [$b.scene.fogStart, $b.settings.fog.start*1.7, $b.settings.fog.start*1.3, $b.settings.fog.start*1.1, $b.settings.fog.start]
    // })];
    // $b.scene.beginDirectAnimation($b.scene, fogAnimation, 0, transitionSeconds*fps, false, 1);
  }
  $b.scene.beginDirectAnimation($b.scene, fogAnimation, 0, transitionSeconds*fps, false, 1);

  // dolly rig
  var velocity = $b.velocity > 1 ? 1 : $b.velocity;
  var position = $b.position(tile[ratio].position);
  var rotation = $b.rotation(tile[ratio].rotation);
  var positionAnimations = [
    // x
    $b.animation({
      name: 'dolly position x',
      property: 'position.x',
      type: 'FLOAT',
      seconds: transitionSeconds * 0.5,
      ease: new BezierCurveEase(0.3, 0, 0.2, 1),
      values: [$b.dolly.position.x, position.x]
    }),

    // y
    $b.animation({
      name: 'dolly position y',
      property: 'position.y',
      type: 'FLOAT',
      seconds: transitionSeconds * 0.5,
      ease: new BezierCurveEase(0.3, 0, 0.2, 1),
      values: [$b.dolly.position.y, position.y]
    })
  ];
  // z
  var positionAnimationZ = $b.animation({
    name: 'dolly position z',
    property: 'position.z',
    type: 'FLOAT',
    seconds: transitionSeconds,
    ease: new BezierCurveEase(0.15, velocity, 0, 1),
    values: [$b.dolly.position.z, $b.offset+position.z]
  });
  positionAnimations.push(positionAnimationZ);

  // dolly rotation
  positionAnimations.push($b.animation({
    name: 'dolly rotation',
    property: 'rotation',
    type: 'VECTOR3',
    seconds: transitionSeconds * 0.8,
    ease: new BezierCurveEase(0.3, 0, 0.2, 1),
    values: [$b.dolly.rotation, rotation]
  }));

  // start event (all transitions)
  if(!$b.state.initialised){
    positionAnimationZ.addEvent(new AnimationEvent(1, function(){
      if($b.number === number){
        $b.state.initialised = true;
      }
    }));
  }

  // finished event (all transitions)
  positionAnimationZ.addEvent(new AnimationEvent(transitionSeconds*fps, function(){
    if($b.number === number){
      $b.state.transitioning = false;
      v.$log('Transition finished', 'Babylon');
    }
  }));

  // animate dolly transition
  $b.scene.beginDirectAnimation($b.dolly, positionAnimations, 0, transitionSeconds*fps, false, 1);
}

/*
* babylon transition
**************************************************************/
export function transition(v, $b, enterPath, leavePath) {
  var name = $b.getNameFromPath(enterPath);
  var tile = $b.tiles[name];

  // intro
  if(!leavePath){
    importMesh(v, $b, name).then(() => {
      pageTransition(v, $b, tile, true);
    });
  } else {

    // load new mesh file
    if(!tile.root){
      importMesh(v, $b, name).then(() => {
        pageTransition(v, $b, tile);
      });

    // get preloaded mesh file
    } else {
      pageTransition(v, $b, tile);
    }
  }
}

/*
* babylon color transition
**************************************************************/
export function transitionColor(v, $b) {
  var $p = v.$project;
  var landscape = $b.materials.landscape;
  if(!$b.state.color){
    $b.state.color = $p.black;
  }
  if($b.state.color !== $p.state.color && landscape){
    var targetColor = new Color3.FromHexString($p.state.color);
    var transitionSeconds = $b.settings.transition.landscapeColorSeconds;
    // animate landscape colour
    var colorAnimations = [];
    each(['ambientColor', 'albedoColor'], function(type){
      colorAnimations.push($b.animation({
        name: 'landscape '+type,
        property: type,
        type: 'COLOR3',
        seconds: transitionSeconds,
        values: [landscape[type], targetColor]
      }));
    });
    $b.scene.beginDirectAnimation(landscape, colorAnimations, 0, transitionSeconds * $b.settings.fps, false, 1);

    // animate fog colour
    // var targetFogColor = $p.state.color === $p.black ? $b.colors.pink : new Color3(targetColor.r+0.25, targetColor.g+0.25, targetColor.b+0.5);
    // $b.scene.beginDirectAnimation($b.scene, [$b.animation({
    //   name: 'fog color',
    //   property: 'fogColor',
    //   type: 'COLOR3',
    //   seconds: transitionSeconds,
    //   values: [$b.scene.fogColor, targetFogColor]
    // })], 0, transitionSeconds * $b.settings.fps, false, 1);

    // set new color
    $b.state.color = $p.state.color;

  }
}
