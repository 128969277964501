import { debounce, each, remove } from 'lodash-es';
export default {
  install(Vue) {

    /*
    * waypoint store
    **************************************************************/
    Vue.prototype.$waypoints = {
      state: new Vue({data(){ return {
        elements: [],
        offset: 0,
      }}}),

      // update waypoint elements
      update: debounce((v) => {
        var $window = v.$window.state;
        var $waypoints = v.$waypoints.state;
        each($waypoints.elements, (waypoint) => {
          var callback = waypoint.args.callback;
          var once = waypoint.args.once;
          var offset = waypoint.args.offset !== undefined ? waypoint.args.offset : $waypoints.offset;
          var detect = $window.height - $window.height * offset;
          var top = waypoint.el.getBoundingClientRect().top;
          var active = detect >= top;
          // is active
          if(active && !waypoint.active && (!once || once && !waypoint.once)){
            waypoint.el.classList.add('waypoint-active');
            v.$set(waypoint, 'active', true);
            if(once){
              v.$set(waypoint, 'once', true);
            }
            if(callback){
              waypoint.args.callback(waypoint, v);
            }
          // is not active
          } else if(!active && waypoint.active){
            waypoint.el.classList.remove('waypoint-active');
            v.$set(waypoint, 'active', false);
            if(callback){
              waypoint.args.callback(waypoint, v);
            }
          }
        });
      }, 24)
    };

    /*
    * waypoint custom function shorthand
    **************************************************************/
    Vue.directive('waypoint', {
      bind: (el, binding, vnode) => {
        if(binding.value !== false){
          // init
          el.classList.add('waypoint');
          var v = vnode.context.$root;
          v.$waypoints.state.elements.push({
            args: binding.value || {},
            el: el,
            active: false,
            once: false
          });
        }
      },
      update: (el, binding, vnode) => {
        if(binding.value !== false){
          // above the fold
          var v = vnode.context.$root;
          var $window = v.$window.state;
          var top = el.getBoundingClientRect().top;
          if(top < $window.height){
            el.classList.add('waypoint-above-the-fold');
          }
          // waypoints
          v.$waypoints.update(v);
        }
      },
      unbind: (el, binding, vnode) => {
        if(binding.value !== false){
          var v = vnode.context.$root;
          remove(v.$waypoints.state.elements, (waypoint) => {
            return waypoint.el === el;
          });
        }
      },
    });

  }
};
