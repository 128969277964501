import { throttle, findKey } from 'lodash-es';
export default {
  install(Vue){

    /*
    * window store
    **************************************************************/
    Vue.prototype.$window = {
      state: new Vue({data(){return{
        width: 0,
        height: 0,
        size: false,
        scroll: parseInt(sessionStorage.scroll) || 0,
        retina: false,
        browser: '',
      }}}),
      breakpoints: {
        'xxs': 330,
        'xs': 480,
        's': 624,
        'ms': 767,
        'm': 840,
        'l': 1024,
        'xl': 1200,
        'xxl': 1600
      },

      // initialise
      init(v){
        var $w = this;
        // size
        window.addEventListener('resize', throttle(function(){
          $w.updateSize();
          v.$waypoints.update(v);
        }, 24));
        $w.updateSize();
        // scroll
        document.addEventListener('scroll', throttle(function(){
          $w.updateScroll();
          v.$waypoints.update(v);
        }, 24), {
          passive: true
        });
        // retina
        $w.state.retina = $w.isRetinaDisplay();
        // browser
        $w.state.browser = $w.isBrowser();
      },

      // update screen properties
      updateSize(){
        var $w = this;
        // set screen size breakpoint initial or full (f)
        $w.state.size = findKey($w.breakpoints, (width) =>
        window.matchMedia('(max-width: '+width+'px)').matches) || 'f';
        // set width and height
        $w.state.width = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
        $w.state.height = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
      },

      // screen size helpers
      isAbove(size){
        return this.state.width > this.breakpoints[size];
      },
      isBelow(size){
        return this.state.width <= this.breakpoints[size];
      },

      // update scroll properties
      updateScroll(){
        var scrolled = parseInt(window.scrollY || document.documentElement.scrollTop);
        this.state.scroll = scrolled;
        sessionStorage.scroll = scrolled;
      },

      // check for retina screen
      isRetinaDisplay(){
        if(window.matchMedia){
          var mq = window.matchMedia("only screen and (min--moz-device-pixel-ratio: 1.3), only screen and (-o-min-device-pixel-ratio: 2.6/2), only screen and (-webkit-min-device-pixel-ratio: 1.3), only screen  and (min-device-pixel-ratio: 1.3), only screen and (min-resolution: 1.3dppx)");
          return (mq && mq.matches || (window.devicePixelRatio > 1));
        }
      },

      // basic browser checks
      isBrowser(){
        if(/^((?!chrome|android).)*safari/i.test(navigator.userAgent)){
          return 'safari-browser';
        } else if (/Edge/.test(navigator.userAgent)) {
          return 'edge-browser';
        } else if (/firefox/i.test(navigator.userAgent)) {
          return 'firefox-browser';
        } else if (/Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor)) {
          return 'chrome-browser';
        }
        return '';
      }
    };

  }
};
