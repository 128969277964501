import { get, each, map, mapValues } from 'lodash-es';
import { Color3, Color4, Vector3, ColorGradient, Animation, EasingFunction } from '@babylonjs/core';
import { init } from './init.js'
import { transition, transitionColor } from './transition.js'
import { render } from './render.js'

/*
* babylon store
**************************************************************/
export default {
  install(Vue) {
    Vue.prototype.$babylon = {

      // reactive
      state: new Vue({data(){ return {
        initialised: false,
        paused: false,
        transitioning: false,
        color: false,
        ratio: false,
      }}}),

      // settings
      settings: {
        debug: false,
        camera: {
          minZ: 0.1,
          maxZ: 140.0,
          keyboardSpeed: 0.4,
        },
        fps: 60,
        fog: {
          start: 10,
          end: 50
        },
        glow: {
          seconds: 2,
          intensity: 4.0
        },
        ring: {
          intensity: 0.03
        },
        transition: {
          move: 70,
          seconds: 6,
          landscapeColorSeconds: 1,
          collisionSeconds: 0.8,
          fadeInSeconds: 0.5,
        }
      },

      // colors
      colors: {
        clear: new Color4(0, 0, 0, 0),
        correction: new Color3.FromHexString('#B5B5BF'),
        black: new Color3.FromHexString('#282333'),
        beacon: new Color3.FromHexString('#3E3D67'),
        pink: new Color3.FromHexString('#ED2377'),
        blue: new Color3.FromHexString('#003CFF'),
        teal: new Color3.FromHexString('#0080FF'),
        dark: new Color3.FromHexString('#000000'),
        light: new Color3.FromHexString('#ffffff'),
      },

      // objects
      scene: null,
      engine: null,
      camera: null,
      dolly: null,
      gradient: null,
      treadmill: null,
      collision: null,

      // lights
      lights: {
        glow: null,
        ring: null,
      },

      // materials
      materials: {
        landscape: null,
        beacon: null,
        energy: null,
      },

      // animations
      animations: {
        glow: null,
        energy: null,
        ring: null,
        collision: null
      },

      // collision rays
      collided: false,
      rays: [
        {
          name: 'above',
          direction: {x: 0, y: 1, z: 0},
          length: 50,
          hit: false,
        },
        {
          name: 'infront',
          direction: {x: 0, y: 0, z: 1},
          length: 3,
          hit: false,
        }
      ],

      // movement,
      number: 0,
      offset: 0,
      previous: 0,
      velocity: 0,

      // tiles
      tiles: {
        default: {
          root: null,
          landscape: {
            position: {x: 0, y: 0.5, z: -31},
            rotation: {x: 14, y: 0, z: 0},
          },
          portrait: {
            position: {x: 0, y: 1, z: -33},
            rotation: {x: 18, y: 0, z: 0},
          }
        },
        home: {
          root: null,
          landscape: {
            position: {x: 0, y: 0.1, z: -18},
            rotation: {x: -4, y: 0, z: 0},
          },
          portrait: {
            position: {x: 0, y: 0, z: -16.5},
            rotation: {x: -1, y: 0, z: 0},
          }
        },
        work: {
          root: null,
          landscape: {
            position: {x: -7, y: 0.5, z: -17},
            rotation: {x: 0, y: -1, z: 1},
          },
          portrait: {
            position: {x: -9, y: 1, z: -16},
            rotation: {x: -1, y: -8, z: 1},
          }
        },
        contact: {
          root: null,
          landscape: {
            position: {x: 1, y: 5.5, z: -21},
            rotation: {x: 0, y: -7, z: 0},
          },
          portrait: {
            position: {x: 1.6, y: 5.5, z: -22},
            rotation: {x: 2, y: -1.5, z: -1},
          }
        },
        who: {
          root: null,
          landscape: {
            position: {x: 0, y: 0, z: -28},
            rotation: {x: 0, y: 0, z: 0},
          },
          portrait: {
            position: {x: 0, y: 0, z: -32},
            rotation: {x: 5, y: 0, z: 0},
          }
        },
      },

      // utils
      getNameFromPath(path){
        var $b = this;
        name = path.replace('/', '').replace(/[^0-9a-z]/gi, '');
        if(name === ''){
          return 'home';
        }
        if(!get($b, 'tiles.'+name)){
          return 'default';
        }
        return name;
      },
      fov(){
        var $b = this;
        if($b.engine && $b.camera && $b.gradient){
          var rect = $b.engine.getRenderingCanvasClientRect();
          var ratio = rect.width / rect.height > 1 ? 'landscape' : 'portrait';
          $b.camera.fov = ratio === 'landscape' ? 0.6 : 0.8;
          $b.gradient.position.y = ratio === 'landscape' ? 0 : 6;
          $b.state.ratio = 'hero-'+ratio;
        }
      },
      parallaxScroll(scroll, height){
        var $b = this;
        if($b.camera){
          var value = scroll / height;
          $b.camera.rotation.x = (0 - value * 8) * (Math.PI / 180);
          $b.camera.position.y = 0 - value * 1.4;
        }
      },
      setPause(v){
        var $b = this;
        if(!$b.state.paused && $b.engine){
          $b.state.paused = true;
          $b.engine.stopRenderLoop();
          v.$log('Paused', 'Babylon');
        }
      },
      setPlay(v){
        var $b = this;
        if($b.state.paused && $b.engine && $b.scene){
          $b.state.paused = false;
          v.$log('Playing', 'Babylon');
          $b.engine.runRenderLoop(function(){
            $b.scene.render();
          });
        }
      },
      animation(options){
        var fps = this.settings.fps;
        var type = Animation['ANIMATIONTYPE_'+options.type];
        var end = options.seconds * fps;
        var animation = new Animation(options.name, options.property, fps, type);
        if(options.ease){
          var ease = options.ease;
          if(options.mode){
            var mode = EasingFunction['EASINGMODE_EASE'+options.mode];
            ease.setEasingMode(mode);
          }
          animation.setEasingFunction(ease);
        }
        animation.setKeys(map(options.values, function(value, i){
          var frame = i === 0 ? 0 : ((i + 1) / options.values.length) * end;
          return {frame: frame, value: value};
        }));
        return animation;
      },
      position(vector){
        return new Vector3(vector.x, vector.y, vector.z);
      },
      rotation(vector){
        var vector = mapValues(vector, function(value){
          return value * (Math.PI / 180);
        });
        return new Vector3(vector.x, vector.y, vector.z);
      },

      // methods
      init(v){
        init(v, this);
      },
      transition(v, enterPath, leavePath){
        transition(v, this, enterPath, leavePath);
      },
      transitionColor(v){
        transitionColor(v, this);
      },
      render(v){
        return render(v, this);
      },
      color(v){
        color(v, this);
      },
    }

  }
};
