import { get, isString } from 'lodash-es';

/*
* SSR
**************************************************************/
const $ssr = window.$ssr ? JSON.parse(window.$ssr) : {};

/*
* CDN
**************************************************************/
const cdn = get($ssr, 'options.cdn_url') || false;
if(cdn){
  cdn += cdn.endsWith('/') ? '' : '/';
  __webpack_public_path__ = cdn;
}

/*
* dependancies
**************************************************************/
// https://github.com/vuejs/vue
import Vue from 'vue'
import VueApp from './app'
// https://github.com/vuejs/vue-router
import Router from 'vue-router'
// https://github.com/MatteoGabriele/vue-analytics
import VueAnalytics from 'vue-analytics'
// https://github.com/shrpne/vue-inline-svg
import {InlineSvgPlugin} from 'vue-inline-svg';

/*
* Hextail
**************************************************************/
import Api from './$/api.js'
import App from './$/app.js'
import Routing from './$/routing.js'
import Media from './$/media.js'
import Window from './$/window.js'
import Utils from './$/utils.js'
import Waypoints from './$/waypoints.js'
import Project from './project.js'
import Babylon from './babylon/prototype.js'

/*
* config
**************************************************************/
Vue.config.productionTip = false;

/*
* debug
**************************************************************/
const domain = window.location.hostname;
const subdomain = domain.split('.');
const debug = domain === process.env.VUE_APP_LOCAL_IP || subdomain[1] === 'dev' || subdomain[1] === 'staging';
Vue.prototype.$log = function(msg, status){
  if(debug){
    var color = isString(status) ? '#5E35B1' : '#00D9C0';
    console.log('%c'+status, 'color:'+color, msg);
  }
};

/*
* router
**************************************************************/
Vue.use(Router)
const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: []
});

/*
* system custom plugins
**************************************************************/
Vue.use(Api);
Vue.use(App);
Vue.use(Routing);
Vue.use(Media);
Vue.use(Window);
Vue.use(Utils);
Vue.use(Waypoints);
Vue.use(Project);
Vue.use(Babylon);

/*
* third-party plugins
**************************************************************/
Vue.use(InlineSvgPlugin);

/*
* babylon
* https://github.com/Beg-in/vue-babylonjs
**************************************************************/
import {
  plugin,
  Scene,
  Camera,
  Entity,
  Plane,
} from 'vue-babylonjs';
// import '@babylonjs/inspector'
Vue.use(plugin, {
  components: {
    Scene,
    Camera,
    Entity,
    Plane,
  }
});

/*
* analytics
**************************************************************/
const analytics = get($ssr, 'options.analytics_id') || false;
Vue.use(VueAnalytics, {
  id: analytics ? analytics : 'UA-000000-0',
  debug: {
    enabled: debug && analytics,
    sendHitTask: !debug
  }
});

/*
* base components
**************************************************************/
const requireComponent = require.context('./components/base', true, /\.vue/);
requireComponent.keys().forEach(fileName => {
  const componentConfig = requireComponent(fileName);
  const componentName = fileName.split('/').pop().replace(/\.\w+$/, '');
  Vue.component(componentName, componentConfig.default || componentConfig);
});

/*
* app
**************************************************************/
const v = new Vue({
  router,
  render: h => h(VueApp),
  mounted(){
    // stagger javascript load
    this.$media.init(this);
    this.$window.init(this);
    this.$api.init(this);
    this.$project.init(this);
  }
}).$mount('#app')
router.beforeEach((to, from, next) => {
  v.$routing.changeRoute(to, from, next, v);
});

/*
* ASCII
**************************************************************/
console.log(`
                   88   88          88
                   88   88          88
.d8888b. 88    88 88888 88 .d888.b8 88d888b.
88'  '88 88    88  88   88 88'  '88 88'  '88
88.  .88 88.  .88  88   88 88.  .88 88.  .88
'88888P' '88888P'  '888 88 '8888'P8 88Y8888'

------------ dev@outlab.digital ------------

`);
