<template>
  <a
    v-if="external && path"
    rel="external noreferrer"
    target="_blank"
    :href="path"
  >
    <slot>
      {{ text }}
      <inline-svg
        v-if="arrow"
        :src="require('@/assets/images/arrow-icon.svg')"
      />
    </slot>
  </a>
  <router-link
    v-else-if="path"
    :to="path"
    :title="text || ''"
    :class="{'router-link-frontpage': path === '/'}"
    @click.native="gaEvent()"
  >
    <slot>
      <span>
        <inline-svg
          v-if="backarrow"
          :src="require('@/assets/images/arrow-icon.svg')"
          class="arrow backarrow"
        />
        {{ text }}
        <inline-svg
          v-if="arrow"
          :src="require('@/assets/images/arrow-icon.svg')"
        />
      </span>
    </slot>
  </router-link>
</template>

<script>
import { get } from 'lodash-es';
export default {
  props: [ 'link', 'arrow', 'backarrow', 'ga' ],
  computed: {
    text(){
      return get(this.link, 'text');
    },
    external(){
      return get(this.link, 'external');
    },
    path(){
      return get(this.link, 'path');
    }
  },
  methods: {
    // google analytics event
    gaEvent(){
      var v = this;
      if(v.ga && v.$ga){
        v.$ga.event('CTA', v.ga, v.text);
      }
    }
  }
}
</script>
