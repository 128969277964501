// import { SceneOptimizer, SceneOptimizerOptions, Scene, Vector3, StandardMaterial, PBRMaterial, SineEase, PointLight, Texture, Mesh } from '@babylonjs/core';
import { Scene, Vector3, StandardMaterial, PBRMaterial, SineEase, PointLight, Texture, Mesh } from '@babylonjs/core';

// create new babylon material
function newMaterial($b, name, albedo, ambient){
  var material = new PBRMaterial(name, $b.scene);
  material.albedoColor = $b.colors[albedo];
  material.ambientColor = $b.colors[ambient];
  // material.alphaMode = Engine.ALPHA_PREMULTIPLIED_PORTERDUFF;
  // material.needDepthPrePass = true;
  // material.transparencyMode = Material.MATERIAL_OPAQUE;
  // material.separateCullingPass = true;
  // material.backFaceCulling = false;
  material.maxSimultaneousLights = 8;
  material.forceDepthWrite = true;
  material.roughness = 0.8;
  material.metallic = 0;
  return material;
}

// create new babylon light
function newLight($b, name, color){
  var light = new PointLight(name, new Vector3(0, 0, 0), $b.scene);
  light.intensity = $b.settings[name].intensity;
  light.diffuse = $b.colors[color];
  light.setEnabled(false);
  return light;
}

// create skybox gradient
function newGradient(v, $b){
  var gradient = $b.scene.getMeshByName('gradient');
  var distance = $b.settings.camera.maxZ - 20;
  gradient.position.z = distance;
  gradient.scaling = new Vector3(distance*3, distance, 10);
  gradient.infiniteDistance = true;
  gradient.renderingGroupId = 0;
  gradient.isPickable = false;
  gradient.applyFog = false;
  var material = new StandardMaterial('gradient', $b.scene);
  material.diffuseTexture = new Texture('/babylon/gradient.svg?v='+v.$app.version, $b.scene);
  material.backFaceCulling = false;
  material.disableLighting = true;
  material.emissiveColor = $b.colors.light;
  gradient.material = material;
  return gradient;
}

// collision fade
function newCollision($b){
  var collision = $b.scene.getMeshByName('collision');
  collision.position.z = 0.3;
  collision.renderingGroupId = 2;
  collision.infiniteDistance = true;
  collision.visibility = 0;
  collision.isPickable = false;
  collision.applyFog = false;
  collision.material = $b.materials.landscape.clone('collision');
  collision.material.disableLighting = true;
  return collision;
}

/*
* babylon init
**************************************************************/
export function init(v, $b) {

  // optimise
  $b.scene.autoClear = false;
  $b.scene.autoClearDepthAndStencil = false;
  // SceneOptimizer.OptimizeAsync($b.scene, SceneOptimizerOptions.ModerateDegradationAllowed());

  // environment
  $b.scene.clearColor = $b.colors.pink;
  $b.scene.ambientColor = $b.colors.correction;
  $b.scene.fogMode = Scene.FOGMODE_LINEAR;
  $b.scene.fogStart = 0;
  $b.scene.fogEnd = 2400;
  $b.scene.fogColor = $b.colors.pink;

  // landscape material
  $b.materials.landscape = newMaterial($b, 'landscape', 'black', 'black');

  // beacon material
  $b.materials.beacon = newMaterial($b, 'beacon', 'black', 'beacon');

  // energy material
  $b.materials.energy = newMaterial($b, 'energy', 'blue', 'dark');
  $b.materials.energy.disableLighting = true;

  // energy/glow animations
  var glowIntensity = $b.settings.glow.intensity;
  var glowSeconds = $b.settings.glow.seconds;
  $b.animations.energy = [$b.animation({
    name: 'energy',
    property: 'emissiveColor',
    type: 'COLOR3',
    seconds: glowSeconds,
    values: [$b.colors.blue, $b.colors.teal, $b.colors.blue],
    ease: new SineEase(),
    mode: 'INOUT'
  })];
  $b.animations.glow = [$b.animation({
    name: 'glow intensity',
    property: 'intensity',
    type: 'FLOAT',
    seconds: glowSeconds,
    values: [glowIntensity/3*2, glowIntensity, glowIntensity/3*2],
    ease: new SineEase(),
    mode: 'INOUT'
  }), $b.animation({
    name: 'glow color',
    property: 'diffuse',
    type: 'COLOR3',
    seconds: glowSeconds,
    values: [$b.colors.blue, $b.colors.teal, $b.colors.blue],
    ease: new SineEase(),
    mode: 'INOUT'
  })];
  $b.animations.ring = [$b.animation({
    name: 'glow color',
    property: 'diffuse',
    type: 'COLOR3',
    seconds: glowSeconds,
    values: [$b.colors.blue, $b.colors.teal, $b.colors.blue],
    ease: new SineEase(),
    mode: 'INOUT'
  })];

  // glow light
  $b.lights.glow = newLight($b, 'glow', 'blue');

  // ring light
  $b.lights.ring = newLight($b, 'ring', 'teal');
  $b.lights.ring.position = new Vector3(0, 0, 0.1);

  // treadmill
  $b.treadmill = $b.scene.getMeshByName('treadmill');
  $b.treadmill.renderingGroupId = 1;

  // collision
  $b.collision = newCollision($b);
  $b.animations.collision = [$b.animation({
    name: 'collision',
    property: 'visibility',
    type: 'FLOAT',
    seconds: $b.settings.transition.collisionSeconds,
    values: [1, 1, 0.5, 0]
  })];

  // gradient
  $b.gradient = newGradient(v, $b);

  // set FOV
  $b.fov();

  // dolly
  $b.dolly = $b.scene.getMeshByName('dolly');
  if(v.$project.state.kiosk){
    $b.settings.transition.seconds = 140;
    $b.velocity = 1;
    $b.dolly.position = $b.position({x: 0, y: 1, z: 15});
    $b.dolly.rotation = $b.rotation({x: 15, y: 0, z: 0});
  } else {
    $b.dolly.position = $b.position({x: 0, y: 1, z: 10});
    $b.dolly.rotation = $b.rotation({x: -6, y: 0, z: 0});
  }

  // configure camera
  if($b.settings.debug){
    $b.camera.speed = $b.settings.camera.keyboardSpeed;
  } else {
    $b.camera.inputs.clear();
  }
  $b.camera.minZ = $b.settings.camera.minZ;
  $b.camera.maxZ = $b.settings.camera.maxZ;
  $b.camera.parent = $b.dolly;
  $b.camera.position = $b.position({x: 0, y: 0, z: 0});
  $b.camera.rotation = $b.rotation({x: 0, y: 0, z: 0});

  // render each frame event
  $b.scene.registerBeforeRender($b.render(v));

  // play first transition
  $b.transition(v, v.$route.path);

  // force color check
  $b.transitionColor(v, $b);
}
