import { get, map, trim } from 'lodash-es';
import zenscroll from 'zenscroll';
export default {
  install(Vue){
    Vue.prototype.$project = {

      /*
      * project store
      **************************************************************/
      state: new Vue({data(){return{
        initialised: false,
        hideContent: false,
        previous: null,
        travelling: {
          contentDelay: 1000,
          noContent: false,
          contentTimeout: null,
          heroDelay: 300,
          heroDelayScroll: 900,
          hideHero: false,
          heroTimeout: null,
        },
        scroll: {
          offset: 100,
          shallow: false,
          hero: false,
          content: false
        },
        homeLinkScrolling: false,
        projectClasses: [],
        fullscreenMenu: false,
        color: '',
        inputSelect: false,
        kiosk: false
      }}}),
      black: '#282333',

      // project first load
      init(v){
        var $p = this;
        v.$log('init', 'Hook');
        $p.state.color = $p.black;
        $p.state.initialised = true;
        $p.state.kiosk = [
          '/brief'
        ].includes(v.$route.path);
      },

      /*
      * project hooks
      **************************************************************/

      // one tick before starting leave transition
      leavingHook(to, from, v){
        var $p = this;
        v.$log('Leaving', 'Hook');

        // babylon transition
        v.$babylon.transition(v, to.path, from.path);
        $p.setTravelling(v);

        // set leave transition
        if($p.state.fullscreenMenu){
          $p.closeFullscreenMenu();
        }

        // record previous route
        $p.setPrevious(from);
      },

      // one tick after route has gone
      leaveHook(v){
        v.$log('Leave', 'Hook');

        // clear post classes
        v.$app.state.thePostClasses = [];
      },

      // post set, one tick before starting enter transition
      enterHook(v, post){
        var $p = this;
        v.$log('Enter', 'Hook');

        // set background color
        $p.setColor(v, post);
      },

      // one tick after enter transition finished
      enteredHook(v){
        v.$log('Entered', 'Hook');
      },

      // route has been interrupted
      interruptedHook(to, from, next, v){
        v.$log('Interrupted', 'Hook');
        next();
      },

      // homepage icon clicked on homepage
      homeLinkHook(){
        var $p = this;
        $p.closeFullscreenMenu();
        $p.state.homeLinkScrolling = true;
        zenscroll.toY(0, 800, function(){
          $p.state.homeLinkScrolling = false;
        });
      },

      /*
      * project functions
      **************************************************************/

      // fullscreen menu
      toggleFullscreenMenu(){
        this.state.fullscreenMenu = !this.state.fullscreenMenu;
      },
      openFullscreenMenu(){
        this.state.fullscreenMenu = true;
      },
      closeFullscreenMenu(){
        this.state.fullscreenMenu = false;
      },

      // background/landscape color
      setColor(v, post){
        var $p = this;
        var hex = get(post, 'content.details.work_page.color.value') || $p.black;
        $p.state.color = hex;
        v.$babylon.transitionColor(v);
      },

      // reload scroll position (when refreshing page)
      reloadScroll(v, show, prev){
        var scroll = v.$window.state.scroll;
        if(show && !prev && scroll > 0){
          setTimeout(() => {
            zenscroll.toY(scroll, 0);
          }, 240);
        }
      },

      // show/hide content
      showContent(){
        this.state.hideContent = false;
      },
      hideContent(){
        this.state.hideContent = true;
      },

      // input select
      openInputSelect(){
        this.state.inputSelect = true;
      },
      closeInputSelect(){
        this.state.inputSelect = false;
      },

      // record previous route
      setPrevious(from){
        this.state.previous = get(from, 'path');
      },

      // scrolled waypoints
      scrollpoint(key, value){
        this.state.scroll[key] = value;
      },

      // travelling
      setTravelling(v){
        var $p = this;

        // set no content then load next post
        $p.state.travelling.noContent = true;
        clearTimeout($p.state.travelling.contentTimeout);
        $p.state.travelling.contentTimeout = setTimeout(() => {
          v.$routing.loadRoute(v);
          $p.state.travelling.noContent = false;
        }, $p.state.travelling.contentDelay);

        // scroll up to hero when above the fold
        // or fade in to prevent long scroll ups
        if($p.state.fullscreenMenu){
          zenscroll.toY(0, 0);
        } else if(v.$window.state.scroll < v.$window.state.height){
          zenscroll.toY(0, $p.state.travelling.heroDelayScroll);
        } else {
          $p.state.travelling.hideHero = true;
          clearTimeout($p.state.travelling.heroTimeout);
          $p.state.travelling.heroTimeout = setTimeout(() => {
            $p.state.travelling.hideHero = false;
          }, $p.state.travelling.heroDelay);
        }
      },

      // split string into sentences
      sentences(text){
        if(!text || trim(text) === ''){
          return '';
        }
        var sentences = text.match(/[^\n.,-]*[\n.,-]?/g);
        var spans = map(sentences, function(sentence){
          if(trim(sentence) === ''){
            return sentence;
          }
          return '<span>'+sentence+'</span>';
        });
        return spans.join('').replace(/(?:\r\n|\r|\n)/g, '<br>');
      },

      // ordinal number
      ordinalNumber(n){
        var j = n % 10,
            k = n % 100;
        if(j == 1 && k != 11) {
          return "st";
        }
        if(j == 2 && k != 12) {
          return "nd";
        }
        if(j == 3 && k != 13) {
          return "rd";
        }
        return "th";
      },
    };

    Vue.prototype.$lazyLoad = function(skeleton){
      return {
        once: false,
        offset: -0.5,
        callback: function(waypoint){
          skeleton.active = !waypoint.active;
        }
      };
    };
  }
};
